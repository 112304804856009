<template>

  <drawer-form
    ref="drawerForm"
    :title="model.id > 0?'编辑':'添加'"
    :model="model"
    :rules="rules"
    :loading="loading"
    @handleSubmit="handleSubmit">


    <a-form-model-item label='图片' prop="logo">
      <image-upload v-model="model.logo" hint="建议尺寸200*200px以上"></image-upload>
    </a-form-model-item>

    <a-form-model-item label='问题' prop="title">
      <a-input v-model="model.title"/>
    </a-form-model-item>


    <a-form-model-item label='图片或视频' prop="video">
      <multi-image-upload ref="multiUpload" v-model="model.video"></multi-image-upload>
    </a-form-model-item>


    <a-form-model-item label='解决方法' prop="content">
      <RichTextField v-model="model.content"></RichTextField>
    </a-form-model-item>

  </drawer-form>

</template>

<script>

  export default {
    data () {
      return {
        isShow: false,
        model: {
          id: null,
          title: null,
          logo: null,
          author: null,
          tag: null,
          content: "",
          video:[]
        },
        rules:[

        ],
        loading: false,

      }
    },
    methods: {
      show (model = {content:""}) {
        this.model = JSON.parse(JSON.stringify(model))
        if(this.model.video){
          this.model.video = JSON.parse(this.model.video)
          setTimeout(() => {
            this.$refs.multiUpload.setValue(this.model.video)
          }, 500)
        }
        this.$refs.drawerForm.show()
      },
      hide () {
        this.$refs.drawerForm.hide()
      },

      handleSubmit () {
        if (!this.model.logo) {
          // this.$message.error('上传图片不能为空')
          // return
        }
        if (this.model.id > 0) {
          this.edit()
        } else {
          this.add()
        }
      },

      add () {
        this.loading = true
        this.$post('web/question', {
          ...this.model,
          video:JSON.stringify(this.model.video)
        }).then(r => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(err => {
          this.loading = false
        })
      },
      edit () {
        this.loading = true
        this.$put('web/question', {
          ...this.model,
          video:JSON.stringify(this.model.video)
        }).then(r => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(err => {
          this.loading = false
        })
      }

    },

  }
</script>

<style>
  .avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }

  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }

  .news-image {
    width: 200px;
  }
</style>
