<template>
  <page-content>

    <common-table
      ref="table"
      path="web/question"
      :columns="columns">

      <template slot="search">
        <a-button  ghost type="primary" @click="add">新增</a-button>
      </template>

      <template slot="logo" slot-scope="{record}">
        <a-avatar shape="square" :size="60" :src="record.logo">{{record.title}}</a-avatar>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-edit  @click="edit(record)"></action-edit>
        <action-delete  @click="remove(record)"></action-delete>
      </template>

    </common-table>

    <question-edit ref="editDrawer" @success="getList"></question-edit>

  </page-content>
</template>
<script>
import QuestionEdit from './QuestionEdit'
export default {
  components: {
    QuestionEdit
  },
  data () {
    return {

    }
  },

  computed: {
    columns () {
      return [
        {
          title: '',
          dataIndex: 'logo',
          scopedSlots: { customRender: 'logo' }
        },
        {
          title: '问题',
          dataIndex: 'title'
        },
        {
          title: '添加时间',
          dataIndex: 'createdAt'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width:120,
          scopedSlots: { customRender: 'operation' }
        }
      ]
    }
  },
  methods: {
    add () {
      this.$refs.editDrawer.show()
    },
    edit (record) {
      this.$refs.editDrawer.show(record)
    },
    remove (record) {
      var that = this
      this.$confirm({
        title: '是否删除该记录?',
        content: '该操作不可撤销',
        centered: true,
        onOk () {
          that.$delete('web/question/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
          })
        }
      })
    },

    getList () {
      this.$refs.table.getData()
    }

  }
}
</script>
<style lang="less" scoped>

.news-logo {
  width: 60px;
  height: 60px;
}
</style>
